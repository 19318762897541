import keccak256 from 'keccak256';
import MerkleTree from "merkletreejs";

export const formatAddress = (value: string, length: number = 4) => {
  return `${value.substring(0, length + 2)}...${value.substring(value.length - length)}`
}

const getMerkleTree = (addresses: string[]): MerkleTree | undefined => {
  try {
    const nodes = addresses.map(address => keccak256(address));
    return new MerkleTree(nodes, keccak256, { sortPairs: true });
  } catch (error) {
    console.log(error);
  }
}

export const getMerkleRoot = (addresses: string[]): string => {
  const tree = getMerkleTree(addresses);
  return tree ? tree.getHexRoot() : "";
}

export const getMerkleProof = (addresses: string[], address: string): string[] => {
  const tree = getMerkleTree(addresses);
  return tree ? tree.getHexProof(keccak256(address)) : [];
}