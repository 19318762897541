import React from 'react';
import { useEffect, useState } from 'react';

import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Space, Button } from 'antd';

import { injected } from '../utils/connectors';
import { useEagerConnect } from '../hooks/useEagerConnect';
import { useInactiveListener } from '../hooks/useInactiveListener';
import { formatAddress } from '../utils/helpers';

const ConnectMetamask = (props: any) => {
  const { account, activate, deactivate, active, connector } = useWeb3React<Web3Provider>();

  const [connectedConnector, setConnectedConnector] = useState<any>();

  useEffect(() => {
    if (connectedConnector && connectedConnector === connector) {
      setConnectedConnector(undefined);
    }
  }, [connectedConnector, connector]);


  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  //const triedEager = useEagerConnect();
  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  //useInactiveListener(!triedEager || !!connectedConnector);

  const onClickConnect = () => {
    setConnectedConnector(injected);
    activate(injected, (error) => {
      if (error) {
        setConnectedConnector(undefined);
      } else {
      }
    });
  }

  const onClickDisconnect = () => {
    setConnectedConnector(undefined);
    deactivate();
  }

  return (
    <div className="btn-wrapper">
      {active ? (
        <a href="#" onClick={onClickDisconnect}
           className="btn btn-outline-white border-2 d-inline-flex align-items-center">
          <svg width="27" height="28" className="ms-2" viewBox="0 0 27 28" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="14" r="12" stroke="currentColor" strokeWidth="3"/>
            <path d="M7.98242 14.9878L11.1954 18.6097" stroke="currentColor" strokeWidth="3"
                  strokeLinecap="round"/>
            <path d="M19.1135 10.4578L11.1952 18.6098" stroke="currentColor" strokeWidth="3"
                  strokeLinecap="round"/>
          </svg>
          <span className="account-name">
            {account ? formatAddress(account) : "Wallet Not Found"}
          </span>
        </a>
      ):(<a href="#" className="btn btn-outline-white" onClick={onClickConnect} >Connect Wallet</a>)}

    </div>
  )
}

export default ConnectMetamask
