import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import { AccountDetails } from '../model/account/AccountDetails';
import { fetchItems } from '../store/account/account-actions';
import { useAppDispatch } from '../store';
import { NFTDetails } from '../model/contract/NFTDetails';

interface Props {
  contractAdress: string
}

const AccountItems = (props: Props) => {
  const {account, active, library} = useWeb3React<Web3Provider>();
  const dispatch = useAppDispatch();

  const accountDetails: AccountDetails = useSelector((state: any) => state.account.accountDetails);

  useEffect(() => {
    if (account && active) {
      dispatch(fetchItems(library, props.contractAdress, accountDetails.ownedItems));
    }
  }, [library, account, active, dispatch, props.contractAdress, accountDetails.ownedItems]);

  const ownedItems: NFTDetails[] = useSelector((state: any) => state.account.ownedItems);

  return (account && active && ownedItems.length > 0 ?
    (<div className="row">
      <div className="col-md-12">
        <h3 className="mb-4">NFT Owned</h3>
      </div>
      <div className="col-md-12">
        <div className="gallery-items">
          {ownedItems.map((item: NFTDetails, i: number) => <div className="gallery-item" key={i}>
            <div className="gallery-image rounded-3">
              <img className="rounded-3" alt={item.name} src={item.image}/>
            </div>
            <div className="gallery-detail">
              <h3>ID-{Number(item.id)}</h3>
            </div>
          </div>)}
        </div>
      </div>
    </div>)
    : <div></div>)
};

export default AccountItems;
