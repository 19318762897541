import React from 'react';
import FooterPatternMidLeft from '../assets/img/footer-pattern-mid-left.png'
import FooterPatternMidRight from '../assets/img/footer-pattern-mid-left.png'

const Footer = (props: any) => {
  return (
    <footer className="site-footer text-white">
      <div className="site-footer__pattern-mid-left-circle">
        <img src={FooterPatternMidLeft} alt=""/>
      </div>
      <div className="site-footer__pattern-mid-right-circle">
        <img src={FooterPatternMidRight} alt=""/>
      </div>
      <div className="site-footer__pattern-top pointer-events-none">
        <svg viewBox="0 0 1440 683" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice">
          <path
            d="M1484.39 0.0391846C1447.03 -0.430847 1414.5 10.1438 1410.99 11.3187C1410.89 11.3579 1410.74 11.397 1410.74 11.397C1383.14 20.7183 1366.11 31.6846 1362.04 34.152C1351.06 40.8101 1294.59 70.1447 1231.62 70.6929C1174.69 71.1629 1118.77 47.9772 1098.85 34.1911C1095.29 31.7237 1082.63 22.4416 1062.2 14.9219C1059.96 14.0994 1051.58 11.162 1037.45 7.79382C1012.44 1.87988 990.735 0.352478 976.147 0.0391846C959.576 0.156677 935.331 1.80157 908.493 9.59546C897.056 12.9245 888.212 16.5276 884.451 18.1334C867.829 25.1831 857.054 32.2328 854.461 33.8778C840.382 42.7682 786.452 68.6172 721.695 68.5781C663.089 68.5781 609.82 47.3114 590.454 34.4653C587.251 32.3112 575.611 24.204 557.211 16.7627C554.365 15.6269 544.046 11.5145 529.001 7.7547C498.808 0.234985 473.597 0.0391846 467.853 0.0391846C454.282 0.0391846 428.511 1.17493 399.335 9.86957C373.87 17.4676 360.756 26.3581 345.71 34.152C336.968 38.6951 279.479 67.9514 212.436 67.6381C161.149 67.4031 107.879 49.8963 80.6858 33.4861C75.6537 30.4704 60.6589 20.875 37.5824 12.8853C21.8253 7.40216 8.10144 4.69983 0.2229 3.36823C-16.6016 0.54834 -30.9355 0 -40.4406 0C-55.4353 0.117493 -119.277 1.95819 -171.631 40.34C-179.306 45.9798 -215.853 73.7087 -227.442 118.357C-229.882 127.717 -230.695 135.746 -230.898 141.66C-230.898 322.094 -231 502.566 -231 683H1675V146.908C1674.85 133.984 1672.31 91.0588 1630.68 52.7946C1575.68 2.19324 1496.54 0.195801 1484.39 0.0391846Z"
            fill="url(#paint0_linear_837_750)"/>
          <defs>
            <linearGradient id="paint0_linear_837_750" x1="1172.4" y1="666.938" x2="693.156" y2="-185.39"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#1D3457"/>
              <stop offset="0.477821" stopColor="#457B9D"/>
              <stop offset="1" stopColor="#A8DADC"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="container position-relative">
        <div className="site-footer__content text-center text-white mx-auto">

          <h3 className="mt-5">This NFT Collection is powered and curated by Non-Fungies Curators</h3>
          <div className="btn-wrapper mt-4 pt-2"><a href="https://nonfungiescurators.com/" target="_blank"
                                                    className="btn btn-red btn-lg">Learn more about NFC</a></div>
        </div>
        <div className="site-footer__bottom">
          <p className="copyrights-text">NFT Top Pioneers Collection</p>
          <div className="site-footer__links">
            <div className="site-footer__links__nav">
              <ul>
                <li><a target="_blank" href="https://topnftpioneers.com/terms-conditions/">Terms of use</a></li>
                <li><a target="_blank" href="https://topnftpioneers.com/privacy-policy/">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="site-footer__links__social">
              <ul>
                <li>
                  <a target="_blank" href="https://twitter.com/nfungiecurators">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        fill="currentColor"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
