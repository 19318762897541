import { contractActions } from './contract-slice';
import { TokenDetails } from '../../model/contract/TokenDetails';
import { BigNumber, Contract } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { CONTRACT_ABI } from '../../abi';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

export const fetchContract = (provider: any, address: string) => {
  return async (dispatch: any) => {
    try {
      const contract: Contract = new Contract(address, CONTRACT_ABI, provider);
      const gasTrancker = new Contract(address, CONTRACT_ABI, provider);

      console.log(contract)

      const code = await provider.getCode(address);
      if (code === '0x')
        return;

      const currentDateTime: number = Date.now() / 1000;

      const tokenDetails: TokenDetails = new TokenDetails();
      tokenDetails.name = await contract.name();
      tokenDetails.symbol = await contract.symbol();
      tokenDetails.address = await contract.address;
      tokenDetails.maxSupply = await contract.MAX_SUPPLY();
      tokenDetails.totalSupply = await contract.totalSupply();
      tokenDetails.presalePrice = Number(formatEther(await contract.PRESALE_PRICE() || 0));
      tokenDetails.presaleStart = await contract.presaleStart();
      tokenDetails.presaleEnd = await contract.presaleEnd();
      tokenDetails.salePrice = Number(formatEther(await contract.PUBLIC_PRICE() || 0));
      tokenDetails.publicStart = await contract.publicStart();
      tokenDetails.owner = await contract.owner();
      tokenDetails.balance = Number(formatEther(await provider?.getBalance(address) || 0));
      tokenDetails.activePrice = (currentDateTime >= tokenDetails.presaleStart && currentDateTime <= tokenDetails.presaleEnd) ? tokenDetails.presalePrice : (currentDateTime >= tokenDetails.publicStart ? tokenDetails.salePrice : 0);

      console.log(tokenDetails);


      dispatch(contractActions.setContract({
        tokenDetails: tokenDetails
      }));
    } catch (error: any) {
      toast.error(error.message);
      // todo process error
    }
  };
};

export const mintNFT = (provider: any, address: string, proof: string[], amount: number) => {
  return async (dispatch: any) => {
    try {
      const signer = provider.getSigner();
      const contract: Contract = new Contract(address, CONTRACT_ABI, signer);

      const code = await provider.getCode(address);
      if (code === '0x')
        return;

      const currentDateTime: number = Date.now() / 1000;
      const presalePrice = await contract.PRESALE_PRICE();
      const presaleStart = await contract.presaleStart();
      const presaleEnd = await contract.presaleEnd();
      const salePrice = await contract.PUBLIC_PRICE();
      const publicStart = await contract.publicStart();
      const currentPrice: BigNumber = (currentDateTime >= presaleStart && currentDateTime <= presaleEnd) ? presalePrice : (currentDateTime >= publicStart ? salePrice : BigNumber.from("0"));

      if (currentPrice.gt(0)) {
        let tx = new Promise((resolve, reject) => resolve(contract.mintNFT(proof, amount, {value: currentPrice.mul(amount)})))
        const toaster = toast.promise(
          tx,
          {
            pending: 'Minting is in process.',
            success: {
              render({data}) {
                dispatch(contractActions.setListenMint({listenMint: true}));
                return `Mint is succeed.`
              },
            },
            error: {
              render({data}) {
                const error: any = data
                if (error.code === 'INSUFFICIENT_FUNDS') {
                  return 'Your funds aren\'t sufficient to purchase.';
                } else if (error.code === 'ACTION_REJECTED') {
                  return 'MetaMask Tx Signature: User denied transaction signature.';
                } else {
                  return error.message;
                }
              },
            }
          }
        )
      } else {
        throw new Error('Can not identify price from SC');
      }
    } catch (error: any) {
      if (error.code === 'INSUFFICIENT_FUNDS') {
        toast.error('Your funds aren\'t sufficient to purchase.');
      } else if (error.code === 'ACTION_REJECTED') {
        toast.error('MetaMask Tx Signature: User denied transaction signature.');
      } else {
        toast.error(error.message);
      }
      // todo process error
    }
  };
};

export const adminMintNFT = (provider: any, address: string, amount: number) => {
  return async (dispatch: any) => {
    try {
      const signer = provider.getSigner();
      const contract: Contract = new Contract(address, CONTRACT_ABI, signer);

      const code = await provider.getCode(address);
      if (code === '0x')
        return;

      let tx = await contract.adminMintNFT(amount);
      console.log(tx);
    } catch (error: any) {
      toast.error(error.message);
      // todo process error
    }
  };
};

export const fetchGasPrice = (provider: any, address: string) => {
  return async (dispatch: any) => {
    try {
      const endDate = moment().format('YYYY-MM-DD')
      const startDate = moment().subtract(1, 'day').format('YYYY-MM-DD')
      const apiKey = 'P1T3TS5E6Z5EQ7DQ7B56TVG7E79DNXPG1N'
      const gasPrice = await axios.get(`https://api.etherscan.io/api?module=stats&action=dailyavggasprice&startdate=${startDate}&enddate=${endDate}&sort=asc&apikey=${apiKey}`)
      // const gasPrice = await axios.get(`https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`)
      console.log(gasPrice);
    } catch (error: any) {
      toast.error(error.message);

      // todo process error
    }
  };
};

export const adminWithdrawFunds = (provider: any, address: string) => {
  return async (dispatch: any) => {
    try {
      const signer = provider.getSigner();
      const contract: Contract = new Contract(address, CONTRACT_ABI, signer);

      const code = await provider.getCode(address);
      if (code === '0x')
        return;

      let tx = await contract.withdraw();
      console.log(tx);
    } catch (error: any) {
      toast.error(error.message);
      // todo process error
    }
  };
};
