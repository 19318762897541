import React from 'react';

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import { Navigate, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';

import Mint from './pages/Mint';
import NotFound from './pages/NotFound';
import Gallery from './pages/Gallery';

window.Buffer = window.Buffer || require('buffer').Buffer;

const App = () => {

  const getLibrary = (provider: any): Web3Provider => {
    const library = new Web3Provider(provider);
    return library
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Routes>
        <Route path="/" element={<Mint/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
      <ToastContainer position="top-right" theme="colored"/>
    </Web3ReactProvider>
  );
}

export default App;
