import { createSlice } from "@reduxjs/toolkit";
import { AccountDetails } from "../../model/account/AccountDetails";

const defaultAccountDetails = new AccountDetails();

const accountSlice = createSlice({
  name: "account",
  initialState: {
    accountDetails: defaultAccountDetails,
    ownedItems: []
  },
  reducers: {
    setAccountDetails(state, action) {
      state.accountDetails = action.payload.accountDetails;
    },
    setOwnedItems(state, action) {
      state.ownedItems = action.payload.ownedItems;
    },
    resetAccountDetails(state) {
      state.accountDetails = defaultAccountDetails;
    }
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice;