import * as React from 'react';

import Header from '../components/Header'
import Footer from '../components/Footer'

const BasicPage = (props: any) => {
  return (
    <div>
      <Header/>
      {props.children}
      <Footer/>
    </div>
  );
};

export default BasicPage;
