import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux'
import accountSlice from "./account/account-slice";
import contractSlice from "./contract/contract-slice";

const store = configureStore({
  reducer: {
    account: accountSlice.reducer,
    contract: contractSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store;