import React from 'react';
import NoWalletConnect from './NoWalletConnect';
import MintButton from './MintButton';
import BgPatterns from './BgPatterns';

const MintNFTTop = ({account, tokenDetails, active, mintClickHandler, onClickConnect}: any) => {
  return (
    <section className="mint-top-section mint-top-section-mint-nft">
      <BgPatterns/>
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="mint-top-section__caption">
              <h1 className="fw-bold">Mint NFT</h1>
              <h3 className="fw-normal">Mint your NFT Pioneers Collection NFTs here!</h3>
              <small className="sub-title">(Maximum number of mints per wallet is 5 NFTs)</small>
            </div>
          </div>
          <div className="col-lg-1 col-sm-12"/>
          {account && active && <div className="col-lg-5 col-sm-12">
            <div className="d-flex flex-column">
              <span>Contract Address</span>
              {tokenDetails && tokenDetails.address &&
              <a className="account-address" href={'https://etherscan.io/address/' + tokenDetails.address}
                 rel="noreferrer" target="_blank">
                {tokenDetails.address}
              </a>}
            </div>
          </div>}
        </div>


        {!account && !active && <NoWalletConnect message={'Your wallet is not connected.'}
                                                 onClickConnect={onClickConnect}/>}

        {account && active && <div className="row">
          <div className="col-md-12">
            <div className="box-wrapper">
              <div className="value-box">
                <div className="d-flex flex-column">
                  <span>Price</span>
                  <div className="nft-value">ETH {tokenDetails?.activePrice}</div>
                </div>
              </div>
              <div className="value-box">
                <div className="d-flex flex-column">
                  <span>Max Supply</span>
                  <div className="nft-value">{Number(tokenDetails?.maxSupply)}</div>
                </div>
              </div>

              <div className="value-box">
                <div className="d-flex flex-column">
                  <span>Minted</span>
                  <div className="nft-value">{Number(tokenDetails?.totalSupply)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <MintButton mintClickHandler={mintClickHandler}/>
          </div>
        </div>}
      </div>
    </section>
  );
};

export default MintNFTTop;
