import { InjectedConnector } from "@web3-react/injected-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [
    1, // mainnet
    5, // goerli
    11155111, // sepolia
    1337 // hardhat
  ]
});

export const getChainById = (id: number | undefined) => {
  switch (id) {
    case 1:
      return "Ethereum Main Network";
    case 5:
      return "Ethereum Goerli Network";
    case 11155111:
      return "Ethereum Sepolia Network";
    case 31337:
      return "Hardhat Local Network";
    default:
      return "Undefined Network";
  }
}