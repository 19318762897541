import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import BasicPage from '../layouts/BasicPage';
import ConnectMetamask from '../components/ConnectMetamask';
import MintNFTTop from '../components/MintNFTTop';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAppDispatch } from '../store';
import { CONTRACT_BY_NETWORK } from '../utils/contract';
import {
  adminMintNFT,
  adminWithdrawFunds,
  fetchContract,
  fetchGasPrice,
  mintNFT
} from '../store/contract/contract-actions';
import { TokenDetails } from '../model/contract/TokenDetails';
import { AccountDetails } from '../model/account/AccountDetails';
import { useSelector } from 'react-redux';
import { injected } from '../utils/connectors';
import GasPriceSection from '../components/GasPriceSection';
import CommonSection from '../components/CommonSection';

const {Content} = Layout;

const Mint = (props: any) => {
  const {account, active, activate, chainId, library} = useWeb3React<Web3Provider>();
  const [connectedConnector, setConnectedConnector] = useState<any>();

  const dispatch = useAppDispatch();

  const CONTRACT_ADDRESS = CONTRACT_BY_NETWORK[chainId ? chainId : 0].address;

  useEffect(() => {
    if (account && active) {
      dispatch(fetchContract(library, CONTRACT_ADDRESS));
      dispatch(fetchGasPrice(library, CONTRACT_ADDRESS));
    }
  }, [account, active, library, CONTRACT_ADDRESS, dispatch]);

  const mintClickHandler = (event: any) => {
    if (account && active && accountDetails) {
      dispatch(mintNFT(library, CONTRACT_ADDRESS, accountDetails.proof, 1));
    }
  }

  const adminMintClickHandler = (event: any) => {
    if (account && active && accountDetails) {
      dispatch(adminMintNFT(library, CONTRACT_ADDRESS, 1));
    }
  }

  const adminWithdrawClickHandler = (event: any) => {
    if (account && active && accountDetails) {
      dispatch(adminWithdrawFunds(library, CONTRACT_ADDRESS));
    }
  }

  const onClickConnect = () => {
    setConnectedConnector(injected);
    activate(injected, (error) => {
      if (error) {
        setConnectedConnector(undefined);
      } else {
      }
    });
  }

  const tokenDetails: TokenDetails = useSelector((state: any) => state.contract.tokenDetails);
  const accountDetails: AccountDetails = useSelector((state: any) => state.account.accountDetails);

  return (
    <BasicPage
      title="Mint NFT"
      extra={[<ConnectMetamask key="connectMetamask" connectText="Connect Wallet"/>]}>
      <MintNFTTop account={account} active={active} tokenDetails={tokenDetails} mintClickHandler={mintClickHandler}
                  onClickConnect={onClickConnect}/>
      <CommonSection>
        {/*<GasPriceSection/>*/}
      </CommonSection>
    </BasicPage>
  );
};

export default Mint;
