import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import BasicPage from '../layouts/BasicPage';
import ConnectMetamask from '../components/ConnectMetamask';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAppDispatch } from '../store';
import { CONTRACT_BY_NETWORK } from '../utils/contract';
import { fetchAccount } from '../store/account/account-actions';
import CommonSection from '../components/CommonSection';
import AccountItems from '../components/AccountItems';
import GalleryTop from '../components/GalleryTop';
import GasPriceSection from '../components/GasPriceSection';
import { injected } from '../utils/connectors';
import { NFTDetails } from '../model/contract/NFTDetails';
import { useSelector } from 'react-redux';
import { mintNFT } from '../store/contract/contract-actions';
import { AccountDetails } from '../model/account/AccountDetails';

const {Content} = Layout;

const Gallery = (props: any) => {
  const {account, active, activate, library, chainId} = useWeb3React<Web3Provider>();
  const [connectedConnector, setConnectedConnector] = useState<any>();
  const dispatch = useAppDispatch();

  const CONTRACT_ADDRESS = CONTRACT_BY_NETWORK[chainId as number] ? CONTRACT_BY_NETWORK[chainId as number].address : '';

  useEffect(() => {
    if (account && active) {
      dispatch(fetchAccount(library, account, chainId, CONTRACT_ADDRESS));
    }
  }, [account, active, library, chainId, dispatch, CONTRACT_ADDRESS]);

  const mintClickHandler = (event: any) => {
    if (account && active && accountDetails) {
      dispatch(mintNFT(library, CONTRACT_ADDRESS, accountDetails.proof, 1));
    }
  }

  const onClickConnect = () => {
    setConnectedConnector(injected);
    activate(injected, (error) => {
      if (error) {
        setConnectedConnector(undefined);
      } else {
      }
    });
  }
  const accountDetails: AccountDetails = useSelector((state: any) => state.account.accountDetails);

  return (
    <BasicPage
      title="Mint NFT"
      extra={[<ConnectMetamask key="connectMetamask" connectText="Connect Wallet"/>]}>
      <Content style={{minHeight: 280}}>
        <GalleryTop account={account} active={active} onClickConnect={onClickConnect} mintClickHandler={mintClickHandler}/>
        <CommonSection>
          <AccountItems contractAdress={CONTRACT_ADDRESS}/>
        </CommonSection>
      </Content>
    </BasicPage>
  );
};

export default Gallery;
