export class TokenDetails {
  address: string = "";
  name: string = "";
  symbol: string = "";
  maxSupply: number = 0;
  totalSupply: number = 0;
  presalePrice: number = 0;
  presaleStart: number = Date.now();
  presaleEnd: number = Date.now();
  salePrice: number = 0;
  publicStart: number = Date.now();
  owner: string = "";
  balance: number = 0;
  activePrice: number = 0;
}