import { accountActions } from './account-slice';
import { formatEther } from '@ethersproject/units';
import { AccountDetails } from '../../model/account/AccountDetails';
import { getMerkleProof } from '../../utils/helpers';
import { whitelistedAccounts } from '../../data/accounts';

import { Contract } from 'ethers';
import { NFTDetails } from '../../model/contract/NFTDetails';
import { CONTRACT_ABI } from '../../abi';
import { toast } from 'react-toastify';


export const fetchAccount = (provider: any, account: string, chainId: number | undefined, address: string) => {
  return async (dispatch: any) => {
    try {
      const accountDetails: AccountDetails = new AccountDetails();
      accountDetails.address = account;
      accountDetails.balance = Number(formatEther(await provider?.getBalance(account) || 0));
      accountDetails.unit = (chainId !== 1 ? 'Test' : '') + 'ETH';

      const proof = getMerkleProof(whitelistedAccounts, account);

      accountDetails.proof = proof;

      const contract: Contract = new Contract(address, CONTRACT_ABI, provider);
      const code = await provider.getCode(address);
      if (code !== '0x') {
        const balance = await contract.balanceOf(account);

        if (balance > 0) {
          for (let index = 0; index < balance; index++) {
            const tokenId = await contract.tokenOfOwnerByIndex(account, index);
            accountDetails.ownedItems.push(tokenId);
          }
        }
      }

      dispatch(accountActions.setAccountDetails({
        accountDetails: accountDetails
      }));
    } catch (error: any) {
      toast.error(error.message);
      // todo process error
    }
  };
};

export const fetchItems = (provider: any, address: string, ids: number[]) => {
  return async (dispatch: any) => {
    try {
      if (ids.length === 0)
        return;

      const data: NFTDetails[] = [];

      for (let index = 0; index < ids.length; index++) {
        const item: NFTDetails = new NFTDetails();
        item.id = ids[index];

        const contract: Contract = new Contract(address, CONTRACT_ABI, provider);
        const code = await provider.getCode(address);
        if (code !== '0x') {
          item.uri = await contract.tokenURI(item.id);
          // https://gateway.ipfs.io/ipfs/QmRaXoRWXmgmR3UokSLvep4CbFbRBZftj8AuCJ6niXQCsw/0
          const response = await fetch(`https://gateway.ipfs.io/ipfs/${item.uri.substring(7)}`, {redirect: 'follow'});

          if (!response.ok) {
            throw new Error('Could not fetch token');
          }

          const responseJSON = await response.json();

          item.name = responseJSON.name;
          item.description = responseJSON.description;
          item.image = `https://gateway.ipfs.io/ipfs/${responseJSON.image.substring(7)}`;

          data.push(item);
        }
      }

      dispatch(accountActions.setOwnedItems({
        ownedItems: data
      }));
    } catch (error: any) {
      toast.error(error.message);
      // todo process error
    }
  };
};
