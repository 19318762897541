import React from 'react';

const NoWalletConnect = ({onClickConnect, message = 'Please connect wallet.'}: any) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="box-wrapper">
          <div className="value-box">
            <div className="d-flex flex-column">
              <h4 className="mb-4 text-center">{message}</h4>
              <div className="text-center mb-4">
                <button onClick={onClickConnect}
                        className="btn btn-red btn-lg">Connect Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoWalletConnect;
