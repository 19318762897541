import React from 'react';

const MintButton = ({mintClickHandler}: any) => {
  return (
    <div className="button-wrapper">
      <button onClick={mintClickHandler}
              className="btn btn-red btn-lg">Mint Now
      </button>
    </div>
  );
};

export default MintButton;
