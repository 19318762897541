import React from 'react';
import MintBg from '../assets/img/mint-bg.png';
import ImageLeft from '../assets/img/side-pattern--left.svg';
import ImageRight from '../assets/img/side-pattern--right.svg';

const BgPatterns = (props: any) => {
  return (
    <div className="absolute">
      <div className="common-section__pattern-bottom pointer-events-none">
        <img src={MintBg} alt=""/>
      </div>
      <div className="common-section--collection__pattern--left pointer-events-none">
        <img src={ImageLeft} alt=""/>
      </div>
      <div className="common-section--collection__pattern--right pointer-events-none">
        <img src={ImageRight} alt=""/>
      </div>
    </div>
  );
};

export default BgPatterns;
