import React from 'react';
import NoWalletConnect from './NoWalletConnect';
import GasPriceSection from './GasPriceSection';
import BgPatterns from './BgPatterns';
import { NFTDetails } from '../model/contract/NFTDetails';
import { useSelector } from 'react-redux';
import MintButton from './MintButton';

const GalleryTop = ({account, active, onClickConnect, mintClickHandler}: any) => {
  const ownedItems: NFTDetails[] = useSelector((state: any) => state.account.ownedItems);
  return (
    <section className="gallery-top-section gallery-top-section-mint-nft">
      <BgPatterns/>
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="mint-top-section__caption">
              <h1 className="fw-bold">Gallery</h1>
              <h3 className="fw-normal">View your NFT Pioneers Collection NFTs here!</h3>
            </div>
          </div>
        </div>

        {!account && !active && <NoWalletConnect message={'Please connect wallet in order to view your NFT collection'}
                                                 onClickConnect={onClickConnect}/>}

        {account && active && ownedItems.length <= 0 && <div className="row mt-4">
          <div className="col-md-12">
            <div className="box-wrapper">
              <div className="value-box">
                <div className="d-flex flex-column">
                  <h4 className="text-center">You don't seem to own any NFT from the NFT Pioneers Collection</h4>
                  <MintButton mintClickHandler={mintClickHandler}/>
                </div>
              </div>
            </div>
          </div>
        </div>}

        {/*<GasPriceSection/>*/}
      </div>
    </section>
  );
};

export default GalleryTop;
