import React from 'react';

import ImageLeft from '../assets/img/side-pattern--left.svg'
import ImageRight from '../assets/img/side-pattern--right.svg'


const CommonSection = (props: any) => {
  return (
    <section className="common-section common-section--collection text-center">
      <div className="container">
        <div className="col-md-12">
          {props.children}
        </div>
      </div>
    </section>
  );
};

export default CommonSection;
