import { CONTRACT_ABI } from "../abi";

export const Networks = {
  Undefined: 0,
  MainNet: 1,
  Goerli: 5,
  Sepolia: 11155111,
  Hardhat: 1337
}

export interface IERC721 {
  address: string
  abi: any
}

export const CONTRACT_BY_NETWORK: {
  [key: number]: IERC721
} = {
  [Networks.Undefined]:
  {
    address: '',
    abi: '',
  },
  [Networks.MainNet]:
  {
    address: '0x2E42961fE0c76AB7cAa9b0A1efC52ec5F154a730',
    abi: CONTRACT_ABI,
  },
  [Networks.Goerli]:
  {
    address: '0x623Ba0AC638050472882B15A3cc0Bc8D66B389fd',
    abi: CONTRACT_ABI,
  },
  [Networks.Sepolia]:
  {
    address: '0xfb0659F92746A345cfef394aD4E1321b7B4519b6',
    abi: CONTRACT_ABI,
  },
  [Networks.Hardhat]:
  {
    address: '0xc6e7DF5E7b4f2A278906862b61205850344D4e7d',
    abi: CONTRACT_ABI,
  },
}
