import React from "react";

import { Result } from "antd";
import { Link } from "react-router-dom";

const NotFound = (props: any) => {
  return <Result status="404" title="404" subTitle="Page not found" extra={<Link to="/">Home</Link>} />;
};

export default NotFound;
