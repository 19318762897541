import { createSlice } from "@reduxjs/toolkit";
import { TokenDetails } from "../../model/contract/TokenDetails";

const defaultTokenDetails = new TokenDetails();

const contractSlice = createSlice({
  name: "contract",
  initialState: {
    tokenDetails: defaultTokenDetails,
    listenMint: false
  },
  reducers: {
    setContract(state, action) {
      state.tokenDetails = action.payload.tokenDetails;
    },
    setListenMint(state, action) {
      state.listenMint = action.payload.listenMint;
    },
  },
});

export const contractActions = contractSlice.actions;

export default contractSlice;
